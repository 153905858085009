import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericLabel, ICard } from 'src/app/_components/generic-card/generic-card.component';
import { TempResultService } from '../../games/temp-result.service';
import { ClaimsService } from 'src/app/_core/services/claims.service';
import { TestEnabled } from 'src/app/_core/classes/DTO/TestEnabled';

@Component({
  selector: 'app-test-mode',
  templateUrl: './test-mode.component.html',
  styleUrls: ['./test-mode.component.scss']
})
export class TestModeComponent implements OnInit {

  modes: Array<ICard> = [
    {
      type : 1,
      title: new GenericLabel('MODEALLENAMENTO', 'black-text'),
      button: {
        label: 'OKBUTTON', action: () =>
          this.navigateToTraining(),
      },
      class: { label: 'allenamento-scheda-img' },
    },
    {
      type : 0,
      title: new GenericLabel('MODETEST', 'black-text'),
      button: {
        label: 'OKBUTTON',
        action: () =>
          this.navigateToTest(),
      },
      class: { label: 'modTest-scheda-img' },
      disabled : false,
    },
  ];

  constructor(private router: Router, private route: ActivatedRoute, private _tempResultService: TempResultService, private claimsService: ClaimsService) { }

  async ngOnInit() {

    const enabledTests: Array<TestEnabled> = await this.claimsService.getEnabledTest();

    if(enabledTests.find(element => element.Category == 0) == undefined) {
      this.modes[1].disabled = true;
    }

    if(enabledTests.find(element => element.Category == 1) == undefined) {
      this.modes[0].disabled = true;
    }

  }

  navigateToTest() {

    this.router.navigate(['test'], { relativeTo: this.route });
    this._tempResultService.testSet();

  }
  navigateToTraining() {

    this.router.navigate(['game'], { relativeTo: this.route });
    this._tempResultService.resetTest();

  }

}
