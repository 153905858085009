import { Injectable } from '@angular/core';
import { CtAuthenticationService } from '@ctsolution/ct-authentication';
import { TestEnabled } from '../classes/DTO/TestEnabled';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {


  constructor (private _auth: CtAuthenticationService){}

  async getCompletionStatusClaims() : Promise<number>{

    await this._auth.getClaims();
    await this._auth.claimsUploaded();
    return this._auth.getJwtValueByContainedKey("CompletionStatus");

  }



  async getEnabledTest() : Promise<Array<TestEnabled>>{

    await this._auth.getClaims();
    await this._auth.claimsUploaded();
    let result : TestEnabled[] | null = <TestEnabled[]>JSON.parse(this._auth.getJwtValueByContainedKey("TestsEnabled"));

    if(result){

      return result;

    }

    return [];
  }

  async getLanguage() : Promise<string | null>{

    await this._auth.claimsUploaded();
    return this._auth.getJwtValueByContainedKey("Language");

  }



}

