import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ICard } from 'src/app/_components/generic-card/generic-card.component';
import { TempResultService } from '../../../games/temp-result.service';
import { Location } from '@angular/common';
import { ClaimsService } from 'src/app/_core/services/claims.service';
import { TestEnabled } from 'src/app/_core/classes/DTO/TestEnabled';


@Component({
  selector: 'app-test-selection',
  templateUrl: './test-selection.component.html',
  styleUrls: ['./test-selection.component.scss'],
})
export class TestSelectionComponent implements OnInit {

  isTest = false;
  modesMobile: Array<ICard> = [
    {
      type : 0,
      title: { label: 'R&PTITLE' },
      subtitle: { label: 'R&PSUBTITLE' },
      class: { label: 'remember-scheda-img' },

      button: {
        label: 'STARTBUTTON',
        action: () => {
          this.router.navigate(['memory'], { relativeTo: this.route });
        },
      },
      disabled : true
    },
    {
      type : 1,
      title: { label: 'A&RTITLE' },
      subtitle: { label: 'A&RSUBTITLE' },
      class: { label: 'listen-scheda-img' },
      button: {
        label: 'STARTBUTTON',
        action: () => {
          this.router.navigate(['verbal'], { relativeTo: this.route });
        },
      },
      disabled : true

    },
    {
      type : 2,
      title: { label: 'G_PTITLE'},
      subtitle: { label: 'G_PSUBTITLE' },
      class: { label: 'generate-scheda-img' },
      button: {
        label: 'STARTBUTTON',
        action: () => {
          this.router.navigate(['generate'], { relativeTo: this.route });
        },
      },
      disabled : true

    },

    {
      type : 3,
      title: { label: 'A_ITITLE',  },
      subtitle: { label: 'A_ISUBTITLE',  },
      class: { label: 'couple-scheda-img' },
      button: {
        label: 'STARTBUTTON',
        action: () => {
          this.router.navigate(['match_numbers'], { relativeTo: this.route });
        },
      },
      disabled : true

    },
  ];
  modesDesktop: Array<ICard> = [
    {
      type : 0,
      title: { label: 'R&PTITLE' },
      subtitle: { label: 'R&PSUBTITLEDESKTOP' },
      class: { label: 'remember-scheda-img' },

      button: {
        label: 'STARTBUTTON',
        action: () => {
          this.router.navigate(['memory'], { relativeTo: this.route });
        },
      },
    },
    {
      type : 1,
      title: { label: 'A&RTITLE' },
      subtitle: { label: 'A&RSUBTITLE' },
      class: { label: 'listen-scheda-img' },
      button: {
        label: 'STARTBUTTON',
        action: () => {
          this.router.navigate(['verbal'], { relativeTo: this.route });
        },
      },


    },
    {
      type : 2,
      title: { label: 'G_PTITLE'},
      subtitle: { label: 'G_PSUBTITLE' },
      class: { label: 'generate-scheda-img' },
      button: {
        label: 'STARTBUTTON',
        action: () => {
          this.router.navigate(['generate'], { relativeTo: this.route });
        },
      },


    },

    {
      type : 3,
      title: { label: 'A_ITITLE', },
      subtitle: { label: 'A_ISUBTITLE', },
      class: { label: 'couple-scheda-img' },
      button: {
        label: 'STARTBUTTON',
        action: () => {
          this.router.navigate(['match_numbers'], { relativeTo: this.route });
        },
      },
      //disabled : true

    },
  ];

  constructor(private router: Router, private route: ActivatedRoute, private _tempResultService: TempResultService, private _location : Location, private claimsService : ClaimsService) {

    const url = this._location.path();
    if(this._tempResultService.isTest)
    {
      this.isTest = this._tempResultService.isTest;
    }
    else{
      if(url.endsWith('test'))
      {
        this.isTest = true;
      }
    }
  }

  async ngOnInit() {

      const enabledTests: Array<TestEnabled> = await this.claimsService.getEnabledTest();
      var checkCategory = 1;
      if (this.isTest){
        checkCategory = 0;
      }

      this.modesDesktop.forEach( element =>{
        var test = enabledTests.find(e => e.Type == element.type && e.Category == checkCategory);
        if (!test) {
          element.disabled = true;
        }
      });
      this.modesMobile.forEach( element => {
        var test = enabledTests.find(e => e.Type == element.type && e.Category == checkCategory);
        if (!test) {
          element.disabled = true;
        }
      });
    };
  }